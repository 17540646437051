export default {

  blue: '#00a8ed',
  gray: '#999',
  grayLight: '#f5f6f5',
  grayMedium: '#666',
  grayDarker: '#262626',
  grayDarkest: 'rgba(0,0,0,0.85)',
  green: '#00c647',
  greenDark: '#005431',
  greenExtra: '#bfe8b0',
  greenLight: '#ceedbf',
  greenLighter: '#def2d4',
  greenLightest: '#eff9e9',
  orange: '#ff5b1d',
  orangeExtra: '#ffe1d7',
  orangeLight: '#fee8e2',
  orangeLighter: '#feeeea',
  orangeLightest: '#fdf7f5',
  pink: '#db51c0',
  pinkExtra: '#e6d0e6',
  pinkLight: '#ebd8eb',
  pinkLighter: '#f6e7f6',
  pinkLightest: '#fef6fe',
  red: '#f10000',
  yellow: '#ffc02b',

  // IG
  igBlue: '#14b4cb',
  igOrange: '#ffd03a',
  igPink: '#ff3a76',
  igRed: '#b70008',
  igGrey: '#f7f7f7',
  igTabBlue: '#0078CF',

  // Project
  ivpBg: '#f6f7f9',
  ivpFg: '#fff',
  ivpText: '#333',
  bclGrey: '#404142',
  bclWhite: '#ffffff',
  bclDarkGrey: '#262729',
  bclRed: '#9b0010',
  bclSale: '#ee2624',
  bclLightGrey: '#ebebeb',
  bclGreen: '#009900',
  bclSand: '#cc9900',
  spiritGold: '#e1b848',
  spiritNoteBg: '#fcc43e',
  spiritNoteBorder: '#cec8bd',

  /* Misc */
  // Based on http://clrs.cc/
  ccAqua: '#7fdbff',
  ccBlue: '#0074d9',
  ccNavy: '#001f3f',
  ccTeal: '#39cccc',
  ccGreen: '#2ecc40',
  ccOlive: '#3d9970',
  ccLime: '#01ff70',
  ccYellow: '#ffdc00',
  ccOrange: '#ff851b',
  ccRed: '#ff4136',
  ccMagenta: '#f012be',
  ccFuchsia: '#f012be',
  ccPurple: '#b10dc9',
  ccMaroon: '#85144b',
  ccPurewhite: 'white',
  ccWhite: '#fafafa',
  ccSilver: '#dddddd',
  ccGray: '#aaaaaa',
  ccBlack: '#111111',
  ccPureblack: 'black',
  ccRaspberry: '#ff3366',
  raspberry: '#ff3366',
  ccOceanblue: '#12afbb',
  ccOceanblueLight: '#d1e9eb',

  // Flat UI Colors - http://flatuicolors.com/
  flatTurquoise: '#1abc9c',
  flatGreensea: '#16a085',
  flatEmerald: '#2ecc71',
  flatNephritis: '#27ae60',
  flatRiver: '#3498db',
  flatBelize: '#2980b9',
  flatAmethyst: '#9b59b6',
  flatWesteria: '#8e44ad',
  flatAsphalt: '#34495e',
  flatMidnight: '#2c3e50',
  flatYellow: '#f1c40f',
  flatOrange: '#f39c12',
  flatCarrot: '#e67e22',
  flatPumpkin: '#d35400',
  flatAlizarin: '#e74c3c',
  flatPomegranate: '#c0392b',
  flatClouds: '#ecf0f1',
  flatSilver: '#bdc3c7',
  flatConcrete: '#95a5a6',
  flatAsbestos: '#7f8c8d',

  // Slider
  dotActive: '#2d5232',
  dotInActive: '#cccccc',
  dotActiveTile: '#ffffff',
  dotInActiveTile: '#008732',
};
